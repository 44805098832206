import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Ev | Güleçler ARENA'a Hoş Geldiniz</title>
        <meta
          name={"description"}
          content={"Hedeflerinizin Önemli Olduğu Yer!"}
        />
        <meta
          property={"og:title"}
          content={"Ev | Güleçler ARENA'a Hoş Geldiniz"}
        />
        <meta
          property={"og:description"}
          content={"Hedeflerinizin Önemli Olduğu Yer!"}
        />
        <meta
          property={"og:image"}
          content={"https://vexolofrix.com/img/1.jpeg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://vexolofrix.com/img/034u61436.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://vexolofrix.com/img/034u61436.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://vexolofrix.com/img/034u61436.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://vexolofrix.com/img/034u61436.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://vexolofrix.com/img/034u61436.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://vexolofrix.com/img/034u61436.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        min-height="100vh"
        lg-padding="120px 0 88px 0"
        md-padding="140px 0 96px 0"
        sm-padding="60px 0 96px 0"
        quarkly-title="Hero-23"
        padding="88px 0 88px 0"
        background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://vexolofrix.com/img/1.jpeg) center center/cover no-repeat"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />

        <Box
          margin="0px 0px 0px 0px"
          md-margin="0px 0px 0px 0px"
          lg-justify-content="center"
          display="flex"
          lg-align-items="center"
          align-items="flex-end"
          lg-width="100%"
          padding="0px 0px 0px 0px"
          justify-content="space-between"
          lg-flex-direction="column"
          width="100%"
          flex-direction="row"
        >
          <Text
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            width="25%"
            md-width="100%"
            margin="0px 0px 0px 0px"
            lg-width="100%"
            color="--light"
            font="--headline2"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            lg-text-align="center"
            lg-margin="0px 0px 20px 0px"
            lg-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Güleçler ARENA'a Hoş Geldiniz
          </Text>
          <Text
            color="#FFFFFF"
            font="--base"
            width="40%"
            lg-width="100%"
            lg-text-align="center"
            margin="0px 0px 0px 0px"
          >
            Futbol tutkunuzu açığa çıkarın! Güleçler ARENA, futbol aksiyonu ve
            heyecanı için önde gelen destinasyonunuzdur. Titizlikle korunan
            sahalarımız, olağanüstü bir oyun deneyimi sunma taahhüdümüzün bir
            kanıtıdır. İster dostça bir maç ister rekabetçi bir turnuvaya ev
            sahipliği yapmayı hedefleyin, kapılarımız sizin için açık.
          </Text>
        </Box>
      </Section>
      <Section
        padding="100px 0 100px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Advantages/Features-29"
      >
        <Override
          slot="SectionContent"
          max-width="1440px"
          align-items="flex-start"
          flex-direction="row"
          flex-wrap="wrap"
          md-flex-direction="column"
          sm-min-width="280px"
        />
        <Box width="15%" sm-width="100%" margin="0px 10px 36px 10px">
          <Text
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--dark"
            sm-margin="0px 0px 20px 0px"
          >
            Neden Bizi Seçmelisiniz?
          </Text>
        </Box>
        <Box width="80%" sm-width="100%" md-width="100%">
          <Box
            border-width="0px 0px 1px 0px"
            margin="0px 0px 50px 0px"
            width="100%"
            border-color="--color-lightD2"
            border-style="solid"
          >
            <Text
              color="--dark"
              letter-spacing="-.7px"
              margin="0px 0px 8px 0px"
              font="--headline3"
            >
              Birinci Sınıf Tesisler
            </Text>
            <Text
              margin="0px 0px 38px 0px"
              font="--base"
              color="--dark"
              letter-spacing="-.7p"
            >
              Üstün bir oyun deneyimi sağlayan FIFA sınıfı çim sahamızın keyfini
              çıkarın.
            </Text>
          </Box>
          <Box
            margin="0px 0px 50px 0px"
            width="100%"
            border-color="--color-lightD2"
            border-style="solid"
            border-width="0px 0px 1px 0px"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--headline3"
              color="--dark"
              letter-spacing="-.7px"
            >
              Gece Oyunu Etkin
            </Text>
            <Text
              margin="0px 0px 38px 0px"
              font="--base"
              color="--dark"
              letter-spacing="-.7p"
            >
              Yüksek güçlü, çevre dostu ışıklarımız, maçınızın asla
              alacakaranlıkta bitmek zorunda olmadığı anlamına gelir.
            </Text>
          </Box>
          <Box
            margin="0px 0px 50px 0px"
            width="100%"
            border-color="--color-lightD2"
            border-style="solid"
            border-width="0px 0px 1px 0px"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--headline3"
              color="--dark"
              letter-spacing="-.7px"
            >
              Merkezi Konum
            </Text>
            <Text
              margin="0px 0px 38px 0px"
              font="--base"
              color="--dark"
              letter-spacing="-.7p"
            >
              Ulaşması kolay, ayrılması zor! Şehrin kalbindeyiz ve sizi
              bekliyoruz.
            </Text>
          </Box>
          <Box
            margin="0px 0px 50px 0px"
            width="100%"
            border-color="--color-lightD2"
            border-style="solid"
            border-width="0px 0px 1px 0px"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--headline3"
              color="--dark"
              letter-spacing="-.7px"
            >
              Uygun Fiyatlar
            </Text>
            <Text
              margin="0px 0px 38px 0px"
              font="--base"
              color="--dark"
              letter-spacing="-.7p"
            >
              Kalite veya deneyimden ödün vermeden rekabetçi fiyatlandırma.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        sm-padding="60px 0 60px 0"
        padding="65px 0 65px 0"
        quarkly-title="Images-9"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="25%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 150% 0px"
          >
            <Image
              left={0}
              min-height="100%"
              top={0}
              right={0}
              bottom={0}
              src="https://vexolofrix.com/img/2.jpeg"
              object-fit="cover"
              position="absolute"
              display="block"
              width="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="25%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
        >
          <Box
            width="100%"
            height="auto"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            padding="0px 0px 150% 0px"
            overflow-x="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              bottom={0}
              min-height="100%"
              src="https://vexolofrix.com/img/3.jpeg"
              object-fit="cover"
              width="100%"
              top={0}
              position="absolute"
              display="block"
              left={0}
              right={0}
            />
          </Box>
        </Box>
        <Box
          lg-align-items="center"
          padding="16px 16px 16px 16px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="25%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
        >
          <Box
            width="100%"
            overflow-x="hidden"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 150% 0px"
            height="auto"
            overflow-y="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              left={0}
              right={0}
              bottom="0px"
              min-height="100%"
              object-fit="cover"
              position="absolute"
              display="block"
              top="auto"
              src="https://vexolofrix.com/img/4.jpeg"
              width="100%"
            />
          </Box>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-order="1"
          width="25%"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          lg-padding="0px 16px 16px 16px"
        >
          <Text
            lg-text-align="center"
            sm-margin="30px 0px 0px 0px"
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            md-text-align="left"
          >
            Oynamaya Hazır mısınız? Bir sonraki zaferinizi Güleçler ARENA'ta
            kazanın - Sahanızı Bugün Ayırtın!
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
